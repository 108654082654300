import React, { useRef, useEffect, useState, useContext, useMemo } from "react"
import { motion, useAnimation, } from 'framer-motion';
import useTheme from "@mui/system/useTheme"
import { alpha } from "@mui/system";

import Box from "@mui/system/Box";
import BowListItem from "./BowListItem";
import { NotInViewContext } from "../AnimateScrollIntoViewport";

const styleSxWrap = {
    "&.bowList": {

        width: "100%",
        opacity: 0.5,
        "& .listParent": {
            listStyle: "none",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            marginTop: 3,
            marginBottom: 3,
            width: "100%",
            perspective: "800px",
            transformStyle: "preserve-3d",
            "& .svg": {
                position: "absolute",
                "& .svgPath": {
                },
                "& .circle": {

                },
            },
            "& .svg2": {
                position: "absolute",
                "& .svgPath2": {
                }
            },
            "& .wrapItemBL": {
                zIndex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                width: "90%",
                perspective: "inherit",
                transformStyle: "inherit",
                "& .itemPaperBL": {
                    padding: 2,
                    textAlign: 'center',
                    display: "flex",
                    maxWidth: "100%",
                    width: "100%",
                    borderRadius: "10px",
                    perspective: "inherit",
                    transformStyle: "inherit",
                    "& .itemBL": {
                        py: 0,
                        borderRadius: "inherit",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        perspective: "inherit",
                        transformStyle: "inherit",
                        "& .divItemText": {
                            zIndex: 1,
                            opacity: 0.1,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignSelf: "center",
                            width: "95%",
                            perspective: "inherit",
                            transformStyle: "inherit",
                            "& .pSecondaryText": {
                                opacity: 0.5
                            }
                        },

                        "& .primaryTextOrComp": {
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "nowrap",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            perspective: "inherit",
                            transformStyle: "inherit",
                        },
                        "& .emoContent": {
                            display: "inline-flex",
                            alignItems: 'center',
                            perspective: "inherit",
                            transformStyle: "inherit",

                            "& .extLink": {
                                color: "unset",
                            },
                            "& .emo": {
                                pl: "5px",
                                pr: "7px",
                                borderRadius: "50%",
                                display: "inline-block",
                            },
                            "& .MuiBadge-root": {
                                display: "inline-block",
                                maxWidth: "100%",
                                flexShrink: 'unset',
                            },
                            "& .content": {
                                display: "inherit",
                                "& p": {
                                    display: "inline",
                                },
                                "& p.MuiTypography-root": {
                                    fontSize: "inherit",
                                }

                            }
                        },
                    }
                }
            }
        },
        "& .divSvgUnder": {
            borderBottomLeftRadius: "200px",
            borderBottomRightRadius: "200px",
            "& .svgUnder": {
                width: "100%",
                display: "block",
                mx: "auto",
                "& .svgPathUnder": {

                }
            }
        },
    }
}




const varWrapBowList = (({ stopItemUseEffectAnimation, xStartList, notInView }) => {
    return {

        initial: notInView ? { x: 0, y: 0, opacity: 1 } : false,
        animate: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                duration: notInView ? 0 : 0.3,
            },

        },
        whileHover: false,
        whileTap: false,
    }
})

const varListParent = ({ bgColorListParent, boxShadowFrom, boxShadowTo, keepBgColor, notInView }) => {
    return {
        initial: notInView ? { boxShadow: boxShadowTo, } : false,
        animate: notInView ? "" : {
            boxShadow: boxShadowTo,
            transition: {
                duration: 0.4,
            }
        },
        whileHover: {
            boxShadow: boxShadowTo,
            transition: {
                duration: 0.5
            }
        },
        whileTap: {
            boxShadow: boxShadowTo,
            transition: {
                duration: 0.5
            }
        },
    }
}


const varSvgPathLeft = ({ fillFrom, notInView }) => ({
    initial: notInView ? { pathLength: 1, fill: alpha(fillFrom, 0), } : { pathLength: 0, },
    animate: notInView ? "" : {
        fill: alpha(fillFrom, 0),
        pathLength: 1,
        transition: {
            duration: 0.2,
            // type: "spring",
            // stiffness: 300,
            // damping: 20,
        },
    },
    whileHover: {
        pathLength: [ null, 0.5, 1 ],
        fill: [ alpha(fillFrom, 0), alpha(fillFrom, 0), fillFrom ],
        strokeWidth: 3,
        transition: {
            duration: 0.5
        }
    },
    whileTap: {
        scale: 0.8,
        pathLength: [ null, 0.5, 1 ],

        fill: "rgb(229, 45, 51,0)",
        transition: {
            repeat: 1,
            repeatType: "reverse",
            duration: 0.5
        }
    },
})


const varSvgPathUnder = ({ notInView }) => ({
    initial: notInView ? { strokeDashoffset: 0 } : false,
    animate: {
        strokeDashoffset: notInView ? 0 : [ 0, 100, 0 ],
        transition: {
            duration: notInView ? 0 : 0.8,
        },
    },
    whileHover: {
        strokeWidth: 10,
        strokeDashoffset: 100,
        transition: {
            repeat: 1,
            repeatType: "reverse",
            duration: 0.5
        }
    },
    whileTap: {
        scale: 0.8,
        strokeWidth: 10,
        strokeDashoffset: 100,
        transition: {
            repeat: 1,
            repeatType: "reverse",
            duration: 0.5
        }
    },
})

const varDivSvgUnder = ({ boxShadowFrom,
    borderBottomLeftRadiusFrom,
    borderBottomRightRadiusFrom,

    boxShadowTo,
    borderBottomLeftRadiusTo,
    borderBottomRightRadiusTo, notInView }) => {
    return {
        initial: notInView ? { boxShadow: boxShadowTo, borderBottomLeftRadius: borderBottomLeftRadiusTo, borderBottomRightRadius: borderBottomRightRadiusTo, } : false,
        animate: notInView ? "" : {
            boxShadow: boxShadowTo,
            borderBottomLeftRadius: borderBottomLeftRadiusTo,
            borderBottomRightRadius: borderBottomRightRadiusTo,
            transition: {
                when: "beforeChildren",
                duration: 0.3
            },
        },
        whileHover: {
            boxShadow: boxShadowFrom,
            borderBottomLeftRadius: borderBottomLeftRadiusFrom,
            borderBottomRightRadius: borderBottomRightRadiusFrom,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            boxShadow: boxShadowFrom,
            borderBottomLeftRadius: borderBottomLeftRadiusFrom,
            borderBottomRightRadius: borderBottomRightRadiusFrom,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },

    }
}

const BowList = ({ name, children, options, inView = true }) => {
    const {
        // name,
        keepBgColor = false,
        nameStyleList,
        stopItemUseEffectAnimation = false,
        angles = "mid",
        tick = 0,
        mainColor = "primary2",
        listWidthWanted = null,
        bowUnder = false,
        bowUnderOptions = { underDeepness: 20, bowDirection: "top" },
        disableAlignTextToBow = false,
        alignAllwaysToLeft = true,
        bowPosition = "left",
        styleBowUnderWrap = {
        },
        bothBows = false,
        bowDirection = "left",
        itemsXDistancePx = 10,
        motionDirection = "left",
        primarySecondarySeparator = "|",
        tooltipSeparator = "||",
        itemPaddingYPx = 2,
        itemMarginYPx = 1,
        listPaddingTopPx = 20,
        listPaddingLeftPx = 5,
        listMarginTopPx = 20,
        listMarginBottomPx = 20,
        listMarginLeftPx = 0,
        styleSvgPath = {},
        styleSvgAside = {},
        styleListFinal = {}
    } = options

    const theme = useTheme()
    const { notInView, isCountedTwice } = useContext(NotInViewContext);

    // console.log("BowList name: ", name)

    const listStyles = {
        primary: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            boxShadow: theme.shadows[ 11 ],
            strokeColor: theme.palette.primary.dark,
        },
        primary2: {
            backgroundColor: theme.palette.primary2.main,
            color: theme.palette.primary2.contrastText,
            boxShadow: theme.shadows[ 11 ],
            strokeColor: theme.palette.primary2.dark,
        },
        secondary: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            boxShadow: theme.shadows[ 11 ],
            strokeColor: theme.palette.secondary.dark,
        },
        secondary2: {
            backgroundColor: theme.palette.secondary2.main,
            color: theme.palette.primary.contrastText,
            boxShadow: theme.shadows[ 11 ],
            strokeColor: theme.palette.secondary2.dark,
        },
    }

    // const handleOnItemTap = ({ e, iItem, controlsBLItem, stopItemUseEffectAnimation }) => {
    //     e.stopPropagation();
    //     if (stopItemUseEffectAnimation) return
    //     controlsBLItem.start("animate",
    //         {
    //             transition: {
    //                 staggerChildren: 0.05,
    //                 duration: 0.3,
    //                 when: "beforeChildren",
    //             }
    //         }
    //     )
    // }


    let tickEmoji

    switch (tick) {
        case 0:
            break;
        case 1:
            tickEmoji = "✔️"
            break;
        case 2:
            tickEmoji = "✅"
            break;

        default:
            break;
    }

    const [ listWidthPx, setListWidth ] = useState(listWidthWanted)
    const listRef = useRef(null)

    useEffect(() => {
        if (listRef.current) {
            setListWidth(listRef.current.offsetWidth)
        }
    }, [ listRef ]);

    let {
        styleList = {},
        itemHeightPx,
        listHeightPx,
        listPaddingBottomPx = 20,
        nameStyleItem,
        styleItem = {
            // boxShadow: theme.shadows[ 1 ],
            boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px',
        },
        listPaddingRightPx = 5,
        listMarginRightPx = 0,
        styleItemPaper = {},
        colorPathStroke = "",
        colorPathFill = ""
    } = options

    let colorListText
    if (nameStyleList) {
        const namedStyleList = listStyles[ nameStyleList ]
        styleList = { ...namedStyleList, ...styleList } || styleList
        colorListText = namedStyleList.color
        if (!colorPathStroke) colorPathStroke = namedStyleList.strokeColor
    }

    if (nameStyleItem) {
        const namedStyleItem = listStyles[ nameStyleItem ]
        styleItem = { ...namedStyleItem, ...styleItem } || styleItem
    }

    const mainColorEmphasized = theme.palette[ mainColor ].main
    const mainColorEmphasizedText = theme.palette[ mainColor ].contrastText
    const bgColorListParent = styleList.backgroundColor ? styleList.backgroundColor : mainColorEmphasized
    const colorListParent = styleList.color ? styleList.color : mainColorEmphasizedText
    const mainColorEmphasizedItem = theme.palette.primary2.light
    const mainColorEmphasizedTextItem = theme.palette.primary2.contrastText


    if (bowUnder) { listPaddingBottomPx = 0 }

    if (!colorPathStroke) colorPathStroke = theme.palette.primary2.dark
    if (!colorPathFill) colorPathFill = theme.palette.primary2.main
    const styleBothBows = {}
    let bowAlignFlex
    if (bothBows) {
        styleSvgAside.zIndex = 1
        styleSvgPath.zIndex = 2
        styleItem.textAlign = "center"
        styleBothBows.justifyContent = "center"
        listPaddingRightPx = listPaddingLeftPx
        listMarginRightPx = listMarginLeftPx
        styleList.alignItems = "center"
        bowAlignFlex = "center"
    }
    else {
        bowAlignFlex = alignAllwaysToLeft ? "flex-start" : bowDirection === "left" ? "flex-start" : "flex-end"
        styleList.alignItems = bowAlignFlex
    }

    if (!listHeightPx) {
        listHeightPx = "auto"
    }

    switch (true) {
        case (!itemHeightPx && (!listHeightPx || (!!listHeightPx && listHeightPx === "auto"))):
            itemHeightPx = 50
            listHeightPx = "auto"
            break;
        case ((!!itemHeightPx && itemHeightPx === "auto") && (!listHeightPx || (!!listHeightPx && listHeightPx === "auto"))):
            itemHeightPx = 50
            listHeightPx = "auto"
            break;
        default:
            break;
    }

    const numberOfItems = children.length
    const remainder = numberOfItems % 2
    const indexShift = remainder > 0 ? 0 : remainder
    const halfOfItems = Math.round(numberOfItems / 2)
    const getItemCurrentXDistance = (ii) => {
        return ii > halfOfItems ? Math.round(itemsXDistancePx - itemsXDistancePx * (numberOfItems - ii + 1) / numberOfItems) : Math.round(itemsXDistancePx - itemsXDistancePx * ii / numberOfItems)
    }

    if (listHeightPx === "auto") {
        listHeightPx = numberOfItems * (itemHeightPx + 2 * itemMarginYPx) + listPaddingTopPx + listPaddingBottomPx
    }
    const listHeight = `${listHeightPx}px`
    const listHeightWithoutPaddingPx = listHeightPx - (listPaddingTopPx + listPaddingBottomPx)
    const heightDistancePx = listHeightWithoutPaddingPx / numberOfItems
    const itemHeightWithoutMargins = heightDistancePx - 2 * itemMarginYPx
    if (itemHeightPx === "auto") {
        itemHeightPx = itemHeightWithoutMargins
    }

    const maxItemsXDistancePxCurrent = getItemCurrentXDistance(halfOfItems)
    const maxXShift = listPaddingLeftPx + (halfOfItems - 1) * maxItemsXDistancePxCurrent
    const itemPY = `${itemPaddingYPx}px`
    const itemMarginY = `${itemMarginYPx}px`

    const listPaddingTop = `${listPaddingTopPx}px`
    const listPaddingBottom = `${listPaddingBottomPx}px`
    const listPaddingLeft = `${listPaddingLeftPx}px`
    const listPaddingRight = `${listPaddingRightPx}px`

    const listMarginTop = `${listMarginTopPx}px`
    const listMarginBottom = `${listMarginBottomPx}px`
    const listMarginLeft = `${listMarginLeftPx}px`
    const listMarginRight = `${listMarginRightPx}px`

    const maxShiftHalf = Math.round(maxXShift / 2)

    const styleItemWidth = {
        width: `calc(100% - ${maxXShift}px)`,
    }

    // let dPath
    // let dPath2
    // let pathStyleXStart
    // let pathStyleXStart2

    // if (bothBows) {

    //     const pathHalfY = Math.round(listHeightPx / 2)
    //     const pathStartY = 0
    //     const pathEndY = listHeightPx - listPaddingBottomPx - listPaddingTopPx
    //     const pathStartX = 2
    //     pathStyleXStart = { left: 0 }
    //     const maxShiftKoef = 1
    //     dPath = `M ${pathStartX} ${pathStartY} Q ${pathStartX + maxShiftKoef * maxXShift} ${pathHalfY} ${pathStartX} ${pathEndY} ${pathStartX + maxShiftKoef * maxShiftHalf} ${pathHalfY} ${pathStartX} ${pathStartY}`
    //     const pathStartX2 = maxXShift - 2
    //     pathStyleXStart2 = { right: 0 }
    //     const maxShiftKoef2 = -1
    //     dPath2 = `M ${pathStartX2} ${pathStartY} Q ${pathStartX2 + maxShiftKoef2 * maxXShift} ${pathHalfY} ${pathStartX2} ${pathEndY} ${pathStartX2 + maxShiftKoef2 * maxShiftHalf} ${pathHalfY} ${pathStartX2} ${pathStartY}`
    // } else {
    //     const pathStartX = bowDirection === "left" ? 2 : maxXShift - 2
    //     const pathHalfY = Math.round(listHeightPx / 2)
    //     const pathStartY = 0
    //     const pathEndY = listHeightPx - listPaddingBottomPx - listPaddingTopPx
    //     pathStyleXStart = bowPosition === "left" ? { left: 0 } : { right: 0 }
    //     const maxShiftKoef = bowDirection === "left" ? 1 : -1
    //     dPath = `M ${pathStartX} ${pathStartY} Q ${pathStartX + maxShiftKoef * maxXShift} ${pathHalfY} ${pathStartX} ${pathEndY} ${pathStartX + maxShiftKoef * maxShiftHalf} ${pathHalfY} ${pathStartX} ${pathStartY}`
    // }



    const { dPath, dPath2, pathStyleXStart, pathStyleXStart2 } = useMemo(() => {
        // function calculatePaths() {
        let dPath, dPath2, pathStyleXStart, pathStyleXStart2;

        if (bothBows) {
            const pathHalfY = Math.round(listHeightPx / 2);
            const pathStartY = 0;
            const pathEndY = listHeightPx - listPaddingBottomPx - listPaddingTopPx;
            const pathStartX = 2;
            pathStyleXStart = { left: 0 };
            const maxShiftKoef = 1;
            dPath = `M ${pathStartX} ${pathStartY} Q ${pathStartX + maxShiftKoef * maxXShift} ${pathHalfY} ${pathStartX} ${pathEndY} ${pathStartX + maxShiftKoef * maxShiftHalf} ${pathHalfY} ${pathStartX} ${pathStartY}`;
            const pathStartX2 = maxXShift - 2;
            pathStyleXStart2 = { right: 0 };
            const maxShiftKoef2 = -1;
            dPath2 = `M ${pathStartX2} ${pathStartY} Q ${pathStartX2 + maxShiftKoef2 * maxXShift} ${pathHalfY} ${pathStartX2} ${pathEndY} ${pathStartX2 + maxShiftKoef2 * maxShiftHalf} ${pathHalfY} ${pathStartX2} ${pathStartY}`;
        } else {
            const pathStartX = bowDirection === "left" ? 2 : maxXShift - 2;
            const pathHalfY = Math.round(listHeightPx / 2);
            const pathStartY = 0;
            const pathEndY = listHeightPx - listPaddingBottomPx - listPaddingTopPx;
            pathStyleXStart = bowPosition === "left" ? { left: 0 } : { right: 0 };
            const maxShiftKoef = bowDirection === "left" ? 1 : -1;
            dPath = `M ${pathStartX} ${pathStartY} Q ${pathStartX + maxShiftKoef * maxXShift} ${pathHalfY} ${pathStartX} ${pathEndY} ${pathStartX + maxShiftKoef * maxShiftHalf} ${pathHalfY} ${pathStartX} ${pathStartY}`;
        }

        return { dPath, dPath2, pathStyleXStart, pathStyleXStart2 };
        // }
        // calculatePaths()
    }, [ bothBows, maxXShift, listHeightPx, listPaddingBottomPx, listPaddingTopPx, bowDirection, bowPosition ]);



    // let dPathUnder
    const styleSvgUnder = {}
    // let styleUnderParent
    let styleFinalUnderParent
    const { underDeepness } = bowUnderOptions

    // if (bowUnder) {
    //     const { bowDirection } = bowUnderOptions

    //     const halfUnderDeepness = Math.round(underDeepness / 2)

    //     const pathStartY = bowDirection === "top" ? 2 : underDeepness - 2
    //     const pathHalfX = Math.round(listWidthPx / 2)

    //     const pathStartX = 0

    //     const pathEndX = listWidthPx - listPaddingLeftPx - listPaddingRightPx

    //     const maxShiftKoef = bowDirection === "top" ? 1 : -1

    //     dPathUnder = `M ${pathStartX} ${pathStartY} Q ${pathHalfX} ${pathStartY + maxShiftKoef * underDeepness} ${pathEndX} ${pathStartY} ${pathHalfX} ${pathStartY + maxShiftKoef * halfUnderDeepness} ${pathStartX} ${pathStartY}`
    //     const underTop = -underDeepness / 2 - listMarginBottomPx + 5
    //     styleUnderParent = {
    //         position: "relative",
    //         paddingRight: listPaddingRight,
    //         paddingLeft: listPaddingLeft,
    //         marginRight: listMarginRight,
    //         marginLeft: listMarginLeft,
    //         top: `${underTop}px`,
    //         height: `${Math.abs(underTop)}px`,
    //     }
    // }

    const { dPathUnder, styleUnderParent, widthSvgUnder, viewBoxSvgUnder } = useMemo(() => {
        let dPathUnder = '';
        let styleUnderParent = {};
        let widthSvgUnder
        let viewBoxSvgUnder

        if (bowUnder) {
            const { bowDirection } = bowUnderOptions;

            const halfUnderDeepness = Math.round(underDeepness / 2);

            const pathStartY = bowDirection === "top" ? 2 : underDeepness - 2;
            const pathHalfX = Math.round(listWidthPx / 2);

            const pathStartX = 0;

            const pathEndX = listWidthPx - listPaddingLeftPx - listPaddingRightPx;

            const maxShiftKoef = bowDirection === "top" ? 1 : -1;

            dPathUnder = `M ${pathStartX} ${pathStartY} Q ${pathHalfX} ${pathStartY + maxShiftKoef * underDeepness} ${pathEndX} ${pathStartY} ${pathHalfX} ${pathStartY + maxShiftKoef * halfUnderDeepness} ${pathStartX} ${pathStartY}`;
            const underTop = -underDeepness / 2 - listMarginBottomPx + 5;
            styleUnderParent = {
                position: "relative",
                paddingRight: listPaddingRight,
                paddingLeft: listPaddingLeft,
                marginRight: listMarginRight,
                marginLeft: listMarginLeft,
                top: `${underTop}px`,
                height: `${Math.abs(underTop)}px`,
            };
            const widthSvgUnderPx = listWidthPx - 2 * listPaddingLeftPx
            widthSvgUnder = `${widthSvgUnderPx}px`
            viewBoxSvgUnder = `0 0 ${widthSvgUnderPx} ${underDeepness}`
        }

        return { dPathUnder, styleUnderParent };
    }, [ bowUnder, bowUnderOptions, listWidthPx, underDeepness, listPaddingRight, listPaddingLeft, listMarginRight, listMarginLeft, listMarginBottomPx ]);


    const styleListTotal = {
        paddingTop: listPaddingTop,
        paddingBottom: listPaddingBottom,
        paddingRight: listPaddingRight,
        paddingLeft: bothBows ? listPaddingRight : bowPosition === "right" ? `${listPaddingLeftPx + maxXShift}px` : bowDirection === "left" ? `${listPaddingLeftPx}px` : `${maxXShift + listPaddingLeftPx}px`,
        marginTop: listMarginTop,
        marginBottom: listMarginBottom,
        marginLeft: "auto",
        marginRight: "auto",

        ...styleList
    }

    if (listWidthPx) {

        // if (bowUnder) {

        styleFinalUnderParent = { ...styleUnderParent, ...styleBowUnderWrap }
        // }
    }

    const xStartList = motionDirection === "left" ? 200 : -200

    return (
        <Box component={motion.div}
            className="bowList"
            sx={styleSxWrap}
            style={{ y: xStartList, x: xStartList, opacity: 0.5, }}
            variants={varWrapBowList({ stopItemUseEffectAnimation, xStartList, notInView: (notInView && !isCountedTwice) })}
            animate="animate"
            initial="initial"
            layout
            whileHover="whileHover"
            whileTap="whileTap"
            key={`BL-${name}-${theme.colorMode}`}
        >
            <motion.ul
                ref={listRef}
                variants={varListParent({ bgColorListParent, boxShadowFrom: theme.shadows[ 20 ], boxShadowTo: theme.shadows[ 1 ], keepBgColor, notInView: (notInView && !isCountedTwice) })}
                className="listParent"
                style={{
                    ...styleListTotal,
                    backgroundColor: bgColorListParent,
                    color: colorListParent,
                    boxShadow: theme.shadows[ 20 ],
                    ...styleListFinal
                }}
                layout
                key={`BL-varListParent-${name}`}
            >
                {
                    children.map((item, i) => {
                        const ii = i + 1
                        let primaryTextOrComp
                        let secondaryText
                        let tooltipText
                        let itemHelp = item
                        if (typeof item === 'string') {
                            const arrT = item.split(tooltipSeparator)
                            if (arrT.length > 1) {
                                tooltipText = arrT[ arrT.length - 1 ]
                                itemHelp = arrT[ 0 ]
                            }
                            const arr = itemHelp.split(primarySecondarySeparator)
                            if (arr.length > 1) secondaryText = arr[ 1 ]
                            primaryTextOrComp = arr[ 0 ]

                        } else {
                            primaryTextOrComp = item
                        }
                        const itemsXDistancePxCurrent = getItemCurrentXDistance(ii)

                        const itemXShiftPx = (ii <= halfOfItems) ? ((ii - 1) * itemsXDistancePxCurrent) : ((numberOfItems - ii - indexShift) * itemsXDistancePxCurrent)
                        const itemXShift = `${itemXShiftPx}px`
                        let directionStyle
                        if (bothBows) {
                            directionStyle = {
                            }
                        } else {
                            directionStyle = bowDirection === "left" ? { left: itemXShift } : { right: itemXShift }
                            directionStyle.maxWidth = "90%"
                        }
                        const bowListItemOptions = {
                            name, colorListText, colorListParent, stopItemUseEffectAnimation, angles, numberOfItems,
                            tickEmoji, index: i, bothBows, mainColorEmphasizedItem, mainColorEmphasizedTextItem, primaryTextOrComp,
                            secondaryText, itemHeight: itemHeightPx, itemPY, itemMarginY, motionDirection, styleItem, styleBothBows, styleItemWidth, directionStyle, styleItemPaper, tooltipText, bowDirection, disableAlignTextToBow, alignAllwaysToLeft,
                        }
                        const itemKey = `${i}-bowListItem`
                        return <BowListItem notInView={notInView && !isCountedTwice} options={bowListItemOptions} key={itemKey} />
                    })}
                <svg
                    className="svg"
                    width={maxXShift}
                    height={listHeight}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        top: listPaddingTop,
                        ...pathStyleXStart,
                        ...styleSvgAside
                    }}
                >
                    <motion.path
                        d={dPath}
                        className="svgPath"
                        variants={varSvgPathLeft({ fillFrom: theme.palette.secondary.main, notInView: (notInView && !isCountedTwice) })}
                        initial="initial"
                        animate="animate"
                        whileHover="whileHover"
                        whileTap="whileTap"
                        stroke={colorPathStroke}
                        fill={theme.palette.secondary.main}
                        strokeWidth={1}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="0 1"
                        style={{
                            ...styleSvgPath,
                            pathLength: 0, //1,
                        }}
                        key={`BL-varSvgPathLeft-${name}`}
                    />
                    <circle
                        strokeWidth={1}
                        r={2}
                        fill="#0ff8dc"
                        stroke="#ECB0ff"
                    >
                        <animateMotion
                            dur="2s"
                            repeatCount={1}
                            path={dPath}
                        />
                    </circle>
                </svg>
                {
                    bothBows && (
                        <svg
                            className="svg2"
                            width={maxXShift}
                            height={listHeight}
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ top: listPaddingTop, ...pathStyleXStart2, ...styleSvgAside }}
                        >
                            <motion.path
                                d={dPath2}
                                className="svgPath2"
                                variants={varSvgPathLeft({ fillFrom: theme.palette.secondary.main, notInView: (notInView && !isCountedTwice) })}
                                initial="initial"
                                animate="animate"
                                whileHover="whileHover"
                                whileTap="whileTap"
                                stroke={colorPathStroke}
                                fill={theme.palette.secondary.main}
                                strokeWidth={1}
                                strokeDasharray="0 1"
                                style={{
                                    ...styleSvgPath,
                                    pathLength: 0, // 1,
                                }}
                                key={`BL-svgPathRight-${name}`}
                            />
                        </svg>
                    )
                }
            </motion.ul>
            {
                bowUnder && (
                    <motion.div
                        variants={varDivSvgUnder({
                            boxShadowFrom: theme.shadows[ 20 ],
                            borderBottomLeftRadiusFrom: "200px",
                            borderBottomRightRadiusFrom: "200px",
                            boxShadowTo: theme.shadows[ 1 ],
                            borderBottomLeftRadiusTo: "0px",
                            borderBottomRightRadiusTo: "0px",
                            notInView
                        })}
                        initial="initial"
                        animate="animate"
                        whileHover="whileHover"
                        whileTap="whileTap"
                        className="divSvgUnder MuiPaper-elevation3"
                        style={{
                            ...styleFinalUnderParent,
                            boxShadow: theme.shadows[ 20 ],
                            borderBottomLeftRadius: "200px",
                            borderBottomRightRadius: "200px",
                        }}
                        key={`BL-varDivSvgUnder-${name}`}
                    >
                        <svg
                            className="svgUnder"
                            width={widthSvgUnder}
                            height={underDeepness}
                            viewBox={viewBoxSvgUnder}
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                ...styleSvgUnder,

                            }}
                        >
                            <motion.path
                                d={dPathUnder}
                                variants={varSvgPathUnder({ notInView })}
                                initial="initial"
                                animate="animate"
                                whileHover="whileHover"
                                whileTap="whileTap"
                                stroke={colorPathStroke}
                                strokeLinecap="round"
                                strokeWidth={1}
                                fill={colorPathFill}
                                pathLength={100}
                                // strokeDasharray="1 1"
                                strokeDasharray="0 1"
                                key={`BL-varSvgPathUnder-${name}`}
                            />
                        </svg>
                    </motion.div>
                )
            }
        </Box>
    )
}




// const MemoizedBowList = React.memo(
//     BowList,
//     (prevProps, nextProps) => {
//         // Perform a deep comparison of the animationKey prop
//         const areStyleSvgPathKeysEqual = JSON.stringify(prevProps.options.styleSvgPath) === JSON.stringify(nextProps.options.styleSvgPath);
//         const areStyleListEqual = JSON.stringify(prevProps.options.styleList) === JSON.stringify(nextProps.options.styleList);
//         const areStyleListFinalEqual = JSON.stringify(prevProps.options.styleListFinal) === JSON.stringify(nextProps.options.styleListFinal);
//         // console.log("MemoizedBowList Keys prevProps", prevProps)
//         // console.log("MemoizedBowList Keys nextProps", nextProps)
//         // console.log("MemoizedBowList Keys JSON.stringify(prevProps.styleSvgPath)", JSON.stringify(prevProps.styleSvgPath))
//         // console.log("MemoizedBowList Keys JSON.stringify(nextProps.styleSvgPath)", JSON.stringify(nextProps.styleSvgPath))
//         // console.log("MemoizedBowList Keys areStyleSvgPathKeysEqual", areStyleSvgPathKeysEqual)
//         // Perform a shallow comparison of the rest of the props
//         const areOtherPropsEqual = Object.keys(prevProps).every(key => {
//             if (key !== 'name' || "children") return true;
//             return prevProps[ key ] === nextProps[ key ];
//         });
//         // console.log("MemoizedBowList areOtherPropsEqual", areOtherPropsEqual)

//         // Only update the component if any of the props have changed
//         return areStyleSvgPathKeysEqual && areStyleListEqual && areStyleListFinalEqual && areOtherPropsEqual;
//     }
// );

const MemoizedBowList = React.memo(
    BowList,
    (prevProps, nextProps) => {
        let isNameEqual = false
        isNameEqual = prevProps.name === nextProps.name;
        return isNameEqual
    }
);

export default MemoizedBowList
