import React from "react"
import Tooltip from "@mui/material/Tooltip"

const styleSxTooltip = {
    color: "primary.contrastText",
    backgroundColor: "primary.main",
    padding: "4px 8px",
    fontSize: "0.8rem",
    maxWidth: "300px",
    wordWrap: "break-word",
    fontWeight: 500,
    lineHeight: "1.4em",
    borderRadius: "20px",
    boxShadow: 15,
}


const TooltipMy = ({ children, title, ...rest }) => {

    return (
        <Tooltip title={title}
            sx={styleSxTooltip}
            style={{ backgroundColor: "yahoo" }}
            name="yahoo"
            {...rest}
        >
            {children}
        </Tooltip>
    )
}

export default TooltipMy
