import React, { useEffect, useMemo, memo } from 'react';
import { motion, useAnimation, } from 'framer-motion';
import InfoIcon from '@mui/icons-material/Info';
import { alpha } from "@mui/system";
import useTheme from "@mui/system/useTheme"
import Badge from '@mui/material/Badge';

import TooltipMy from "../tooltipMy"


const varWrapItem = ({ startXPos, startYPos, bgColorWrapItemBL, colorListText, notInView }) => {
    return {
        initial: notInView ? { x: 0, y: 0, opacity: 1, color: colorListText, } : false,
        animate: {
            x: notInView ? 0 : [ startXPos, 0, 0 ],
            y: notInView ? 0 : [ startYPos, 0, 0 ],
            opacity: 1,
            color: colorListText,
            transition: {
                duration: notInView ? 0 : 0.3,
                when: "beforeChildren",
            },
        },
    }
}

const varPaperItem = ({ startXPos: xFrom, shadowHoverTapPaperItem, bothBows, bgColorPaperItem, colorListText, notInView }) => {
    const styleHoverOneBowItem = bothBows ? {} : {
        padding: 0,
    }
    return {
        initial: notInView ? {
            x: 0, rotateX: 0, color: colorListText,
            scale: 1
        } : false,
        animate: {
            x: 0,
            rotateX: 0,
            color: colorListText,
            scale: 1,
            transition: {
                duration: notInView ? 0 : 0.2,
            },
        },
        whileHover: {
            ...styleHoverOneBowItem,
            boxShadow: shadowHoverTapPaperItem,
        },
        whileTap: {
            ...styleHoverOneBowItem,
            boxShadow: shadowHoverTapPaperItem,
            textAlignLast: "center",
        },
    }
}



const varItemBL = ({ isBgColorItemBLColor, bgColorItemBL, boxShadowMid, boxShadowTo, bowDirection
    , disableAlignTextToBow, alignAllwaysToLeft, colorListText, notInView }) => {
    const styleAlignTo = (disableAlignTextToBow) ? {} : alignAllwaysToLeft ? { alignItems: "flex-start" } : bowDirection === "left" ? { alignItems: "flex-start" } : { alignItems: "flex-end" }

    return {
        initial: notInView ? {
            backgroundColor: isBgColorItemBLColor ? alpha(bgColorItemBL, 0) : bgColorItemBL,
            ...styleAlignTo,
            boxShadow: "none",
            color: colorListText,
        } : false,
        animate: {
            backgroundColor: isBgColorItemBLColor ? alpha(bgColorItemBL, 0) : bgColorItemBL,
            ...styleAlignTo,
            boxShadow: [ boxShadowTo, boxShadowMid, boxShadowTo, "none" ],
            color: colorListText,
            transition: {
                backgroundColor: {
                    duration: 0.5,
                },
                duration: notInView ? 0 : 0.5,
                times: [ 0, 0.2, 0.9, 1 ],
            },
        },
        whileHover: {
            boxShadow: boxShadowMid,
            alignItems: "center",
            transition: {
                duration: 1.25,
            },
        },
        whileTap: {},
    }
}


const varDivItemText = ({ index, bothBows, bgColorTapDivItemText, colorTapDivItemText, startXPos, startYPos, startAngleZ, angleTo, bgColorDivItemText, bowDirection, disableAlignTextToBow, alignAllwaysToLeft, colorListText, notInView }) => {
    const delayIn = index * 0.1
    const delayOut = index * 0.05
    const styleHoverOneBowItem = bothBows ? { scale: 0.9 } : {
        marginTop: 0,
        marginBottom: 0,
    }
    const styleAlignTo = (disableAlignTextToBow) ? {} : alignAllwaysToLeft ? { alignItems: "flex-start" } : bowDirection === "left" ? { alignItems: "flex-start" } : { alignItems: "flex-end" }
    return {
        initial: notInView ? { x: 0, y: 0, ...styleAlignTo, opacity: 1, scale: 1, color: colorListText, } : false,
        animate: {
            x: 0,
            y: 0,
            ...styleAlignTo,
            opacity: 1,
            scale: 1,
            color: colorListText,
            transition: {
                duration: notInView ? 0 : 0.5,
                when: "beforeChildren",
                delay: delayIn,
            },
        },
        whileHover: {
            opacity: 1,
            ...styleHoverOneBowItem,
        },
        whileTap: {
            scale: 0.8,
            opacity: 1,
            backgroundColor: alpha(bgColorTapDivItemText, 1),
            color: alpha(colorTapDivItemText, 1),
            ...styleHoverOneBowItem,
        },
    }
}

const varEmoContent = ({ index, bowDirection, disableAlignTextToBow, alignAllwaysToLeft, colorListText, colorListParent, notInView }) => {
    const styleAlignTo = (disableAlignTextToBow) ? {} : alignAllwaysToLeft ? { textAlign: "left" } : { textAlign: bowDirection }

    return {
        initial: notInView ? { ...styleAlignTo, color: colorListText, } : false,
        animate: {
            ...styleAlignTo,
            color: colorListText,
            transition: {
                duration: notInView ? 0 : 0.5,
            },
        },
        whileHover: {
            textAlign: "center",
            transition: {
                duration: 0.5,
            },
        },
        whileTap: {},
    }
}

const varPSecondaryText = ({ motionDirection, index, startXPos, startOpacity, notInView }) => {
    const delayIn = index * 0.1
    const delayOut = index * 0.05
    return {
        initial: notInView ? { opacity: 1, x: 0, } : false,
        animate: {
            opacity: 1,
            x: 0,
            transition: {
                duration: notInView ? 0 : 0.2,
                when: "beforeChildren",
                delay: delayIn,
            },
        },
        whileHover: false,
        whileTap: false,
    }
}

function calculateStartAngleZ(stopItemUseEffectAnimation, numberOfItems, angles, index) {
    const halfNumberOfItems = Math.round(numberOfItems / 2);
    let startAngleZ = 0; // Default value

    if (!stopItemUseEffectAnimation && !(numberOfItems <= 2 && angles === "mid")) {
        if (angles === "mid") {
            if (index < halfNumberOfItems) {
                startAngleZ = 360 - Math.round((halfNumberOfItems - index) / numberOfItems * 30);
            } else {
                startAngleZ = Math.round((index - halfNumberOfItems) / numberOfItems * 30);
            }
        } else if (angles === "up") {
            startAngleZ = 360 - Math.round((numberOfItems - index) / numberOfItems * 60);
        } else if (angles === "down") {
            startAngleZ = Math.round((index + 1) / numberOfItems * 60);
        }
    }

    return startAngleZ;
}

function calculateAngleTo(stopItemUseEffectAnimation, numberOfItems, angles, index) {
    let angleTo = 360
    if (!stopItemUseEffectAnimation) {
        if (angles === "down") {
            angleTo = 0; // As per the provided context
        }
        // else {
        //     // Assuming a default or calculated value for angleTo in other scenarios
        //     // This is a placeholder, replace with actual logic
        //     angleTo = calculateDefaultAngleTo(numberOfItems, angles, index);
        // }
    }
}

const BowListItem = ({ notInView, options }) => {
    const { name, colorListText, colorListParent, stopItemUseEffectAnimation, angles, numberOfItems,
        tickEmoji, index, bothBows, mainColorEmphasizedItem, primaryTextOrComp, secondaryText, itemHeight, itemPY, itemMarginY, motionDirection, styleItem, styleBothBows, styleItemWidth, directionStyle, tooltipText, bowDirection, disableAlignTextToBow, alignAllwaysToLeft,
    } = options
    // const startXPos = stopItemUseEffectAnimation ? 0 : motionDirection === "left" ? -50 : 50
    // const startYPos = stopItemUseEffectAnimation ? 0 : index > numberOfItems / 2 ? -50 : 50
    const { startXPos, startYPos } = useMemo(() => ({
        startXPos: stopItemUseEffectAnimation ? 0 : motionDirection === "left" ? -50 : 50,
        startYPos: stopItemUseEffectAnimation ? 0 : index > numberOfItems / 2 ? -50 : 50
    }), [ stopItemUseEffectAnimation, motionDirection, index, numberOfItems ]);

    const controlsBLItem = useAnimation()
    const theme = useTheme()

    const transformOrigin = "left"

    // let startAngleZ
    // let angleTo = 360
    // const halfNumberOfItems = Math.round(numberOfItems / 2)
    // if (stopItemUseEffectAnimation || (numberOfItems <= 2 && angles === "mid")) {
    //     startAngleZ = 0
    // } else {
    //     if (angles === "mid") {
    //         if (index < halfNumberOfItems) {
    //             startAngleZ = 360 - Math.round((halfNumberOfItems - index) / numberOfItems * 30)
    //         } else {
    //             startAngleZ = Math.round((index - halfNumberOfItems) / numberOfItems * 30)

    //         }
    //     }

    //     if (angles === "up") {
    //         startAngleZ = 360 - Math.round((numberOfItems - index) / numberOfItems * 60)
    //     }
    //     if (angles === "down") {
    //         startAngleZ = Math.round((index + 1) / numberOfItems * 60)
    //         angleTo = 0
    //     }
    // }


    // Usage within the component
    // const startAngleZ = calculateStartAngleZ(stopItemUseEffectAnimation, numberOfItems, angles, index);




    const { startAngleZ, angleTo } = useMemo(() => {
        // Calculation logic here...
        // Return the calculated values
        return { startAngleZ: calculateStartAngleZ(stopItemUseEffectAnimation, numberOfItems, angles, index), angleTo: calculateAngleTo(stopItemUseEffectAnimation, numberOfItems, angles, index) };
    }, [ stopItemUseEffectAnimation, numberOfItems, angles, index ]);


    const bgColorWrapItemBL = theme.palette.secondary.light
    const bgColorPaperItem = mainColorEmphasizedItem
    const bgColorItemBL = styleItem.backgroundColor ? styleItem.backgroundColor : "#451100"
    const bgColorDivItemText = theme.palette.primary.main

    const isBgColorItemBLColor = ![ 'inherit', 'unset', 'initial', 'transparent' ].includes(bgColorItemBL)

    const bgColorTapDivItemText = theme.palette.primary2.main
    const colorTapDivItemText = theme.palette.primary2.contrastText


    const shadowHoverTapPaperItem = theme.shadows[ 24 ]

    useEffect(() => {
        if (!stopItemUseEffectAnimation) {
            controlsBLItem.start("animate"
            )
        }
        return () => {
            if (!stopItemUseEffectAnimation) controlsBLItem.stop()
        }
    }, [ controlsBLItem, stopItemUseEffectAnimation ])

    const isStringPrimaryTextOrComp = typeof primaryTextOrComp === "string"
    const isTooltip = !!tooltipText
    const badgeContent = <InfoIcon color="secondary" />

    const ContentComp = useMemo(() => (
        isStringPrimaryTextOrComp ? (
            <motion.div
                variants={varDivItemText({
                    index, bothBows, bgColorTapDivItemText, colorTapDivItemText, startXPos, startYPos, startAngleZ, angleTo, bgColorDivItemText, bowDirection, disableAlignTextToBow, alignAllwaysToLeft, colorListText, notInView
                })}
                className="divItemText MuiListItemText-root"
                style={{
                    x: startXPos,
                    y: startYPos,
                    opacity: 0,
                    transformOrigin,
                    backgroundColor: alpha(bgColorDivItemText, 0),
                    alignItems: "center",
                }}
                layout
                key={`contentComp-${name}-${index}`}
            >
                {isTooltip ? (
                    <motion.div
                        variants={varEmoContent({
                            index, bowDirection
                            , disableAlignTextToBow, alignAllwaysToLeft, colorListText, colorListParent, notInView
                        })}
                        layout
                        className="emoContent"
                        style={{
                            color: colorListParent,
                            textAlign: "center",
                            height: "inherit",
                        }}
                        key={`varEmoContent-${name}-${index}`}
                    >

                        {tickEmoji && (<div className="emo">
                            {tickEmoji}
                        </div>)}

                        <Badge badgeContent={badgeContent} color="primary" style={{ flexShrink: 'unset' }}>
                            <div className="content" style={{ display: 'contents' }}>
                                {primaryTextOrComp}
                            </div>
                        </Badge>
                    </motion.div>
                ) : (
                    <motion.div
                        variants={varEmoContent({
                            index, bowDirection
                            , disableAlignTextToBow, alignAllwaysToLeft, colorListText, colorListParent, notInView
                        })}
                        layout
                        className="emoContent"
                        style={{
                            color: colorListParent,
                            textAlign: "center",
                            height: "inherit",
                        }}
                        key={`varEmoContent-${name}-${index}`}
                    >

                        {tickEmoji && <div className="emo">
                            {tickEmoji}
                        </div>}

                        <div className="content" style={{ display: 'contents' }}>
                            {primaryTextOrComp}
                        </div>
                    </motion.div>
                )}
                {

                    !!secondaryText && (
                        <motion.div
                            variants={varPSecondaryText({ motionDirection, index, startXPos, startOpacity: 0.5, notInView })}
                            className="pSecondaryText MuiTypography-root MuiTypography-colorTextSecondary"
                            style={{ x: startXPos, opacity: 0.5 }}
                            layout
                            key={`varPSecondaryText-${name}-${index}`}
                        >
                            {secondaryText}
                        </motion.div>)
                }
            </motion.div>
        ) : (
            <motion.div
                variants={varDivItemText({ index, bothBows, bgColorTapDivItemText, colorTapDivItemText, startXPos, startYPos, startAngleZ, angleTo, bgColorDivItemText, bowDirection, disableAlignTextToBow, colorListText, notInView })}
                className="primaryTextOrComp"
                style={{
                    x: startXPos, y: startYPos, opacity: 0,
                    transformOrigin, backgroundColor: alpha(bgColorDivItemText, 0),
                }}
                layout
                key={`varDivItemText-${name}-${index}`}
            >
                <motion.div
                    variants={varEmoContent({
                        index, bowDirection
                        , disableAlignTextToBow, alignAllwaysToLeft, colorListText, colorListParent, notInView
                    })}
                    layout
                    className="emoContent"
                    style={{
                        textAlign: "center",
                    }}
                    key={`varEmoContent-${name}-${index}`}
                >

                    {tickEmoji && <div className="emo">
                        {tickEmoji}
                    </div>}

                    <div className="content" style={{ display: 'contents' }}>
                        {primaryTextOrComp}
                    </div>
                </motion.div>
                {isTooltip && <Badge badgeContent={badgeContent} color="primary"></Badge>}
            </motion.div>
        )
    ), [ isStringPrimaryTextOrComp, primaryTextOrComp, isTooltip, badgeContent, tickEmoji, secondaryText, bgColorTapDivItemText, colorTapDivItemText, startXPos, startYPos, startAngleZ, angleTo, bgColorDivItemText, bowDirection, disableAlignTextToBow, alignAllwaysToLeft, colorListText, notInView ])

    return (
        <motion.div
            className="wrapItemBL"
            style={{
                ...directionStyle, x: startXPos, y: startYPos,
                transformOrigin,
                ...styleItemWidth,
                opacity: 0.5,
            }}
            variants={varWrapItem({ startXPos, startYPos, bgColorWrapItemBL, colorListText, notInView })} //startAngleZ, angleTo,
            animate={stopItemUseEffectAnimation ? "animate" : controlsBLItem}
            layout
            key={`varWrapItem-${name}-${index}`}
        >
            <motion.div
                className={`itemPaperBL MuiPaper-elevation3 MuiPaper-rounded`}
                variants={varPaperItem({ startXPos, shadowHoverTapPaperItem, bothBows, bgColorPaperItem, colorListText, notInView })}
                initial="initial"
                animate="animate"
                whileHover="whileHover"
                whileTap="whileTap"
                layout
                style={{
                    x: startXPos,
                    rotateX: 180,
                    scale: 1,
                    height: itemHeight,
                    paddingTop: itemPY,
                    paddingBottom: itemPY,
                    marginTop: itemMarginY,
                    marginBottom: itemMarginY,
                    backgroundColor: alpha(bgColorPaperItem, 0),
                }}
                key={`varPaperItem-${name}-${index}`}
            >
                <motion.li
                    layout
                    className="itemBL"
                    variants={varItemBL({
                        isBgColorItemBLColor, bgColorItemBL, boxShadowMid: theme.shadows[ 11 ], boxShadowTo: theme.shadows[ 1 ], bowDirection
                        , disableAlignTextToBow, alignAllwaysToLeft, colorListText, notInView
                    })}
                    style={{
                        backgroundColor: isBgColorItemBLColor ? alpha(bgColorItemBL, 0) : bgColorItemBL,
                        boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px',
                        ...styleBothBows,
                        ...styleItem,
                    }}
                    key={`varItemBL-${name}-${index}`}
                >
                    {isTooltip ? (<TooltipMy title={tooltipText} arrow={true} placement="top-end" >
                        {ContentComp}
                    </TooltipMy>) : ContentComp}
                </motion.li>
            </motion.div>
        </motion.div>

    )
}

export default BowListItem;